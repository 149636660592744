//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { throttle } from 'throttle-debounce';
import { cleanObj } from '~/utils';
import FilterMenu from './FilterMenu';

export default {
  name: 'Searchbar',
  components: {
    FilterMenu,
  },
  props: {
    details: {
      type: Boolean,
      default: false,
    },
    isFixed: {
      type: Boolean,
      default: false,
    },
    searchOnMounted: {
      type: Boolean,
      default: false,
    },
    searchApi: {
      type: String,
      default: '',
    },
    conditions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      suggestKeywords: [],
      searchPayload: {
        keywords: '',
        areaIds: null,
        jobCatIds: null,
        size: 9,
        current: 1,
      },
      detailsPayload: {},
      isSearched: false,
      searchbarHeight: 212,
      isDown: false,
    };
  },
  computed: {
    ...mapGetters('common', ['areaLocalTree', 'jobCatTree']),
  },
  methods: {
    getSuggestKeywords() {
      this.$api('getKeywords').then(data => {
        this.suggestKeywords = data.map(item => item.value);
      });
    },
    setSearchbarHeight() {
      this.searchbarHeight = this.$refs.searchbarArea.clientHeight;
    },
    onScroll() {
      if (process.browser) {
        const windowTop = window.pageYOffset;
        this.isDown = windowTop >= this.searchbarHeight;
      }
    },
    filterChange(payload) {
      this.detailsPayload = payload;
    },
    onSearch() {
      const payload = {
        ...this.searchPayload,
        ...this.detailsPayload,
      };
      this.$api(this.searchApi || 'searchJobs', cleanObj(payload, true)).then(
        data => {
          this.$emit('search', data);
        },
      );
    },
    addKeyword(word) {
      this.searchPayload.keywords = word;
    },
    openSearch() {
      if (process.browser) {
        window.scroll({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
  },
  watch: {
    conditions(newConditions) {
      for (const [key, value] of Object.entries(newConditions)) {
        if (value !== null || value !== '') {
          this.searchPayload[key] = newConditions[key];
        }
      }
      this.onSearch();
    },
  },
  mounted() {
    this.getSuggestKeywords();
    if (this.searchOnMounted) {
      this.onSearch();
    }
    this.setSearchbarHeight();
    if (process.browser) {
      window.addEventListener('scroll', throttle(200, false, this.onScroll), {
        passive: true,
      });
      window.addEventListener('resize', this.setSearchbarHeight, {
        passive: true,
      });
    }
  },
  destroyed() {
    if (process.browser) {
      window.removeEventListener(
        'scroll',
        throttle(200, false, this.onScroll),
        {
          passive: true,
        },
      );
      window.removeEventListener('resize', this.setSearchbarHeight, {
        passive: true,
      });
    }
  },
};
