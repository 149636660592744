//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FilterMenu',
  props: {
    menu: {
      type: Array,
      default: () => {},
    },
    activeItem: {
      type: String,
      default: null,
    },
  },
  methods: {
    clickfilterItem(value) {
      this.$emit('filterChange', value);
    },
  },
};
