//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { cleanObj } from '~/utils';

export default {
  name: 'FilterMenu',
  props: { hidden: { type: Boolean, default: false } },
  data() {
    return {
      filterOptions: {
        updateTime: [
          {
            value: 1,
            label: '本日最新',
          },
          {
            value: 3,
            label: '三日內',
          },
          {
            value: 7,
            label: '一週內',
          },
          {
            value: 14,
            label: '兩週內',
          },
          {
            value: 30,
            label: '一個月內',
          },
        ],
        type: [
          {
            value: 0,
            label: '全職',
          },
          {
            value: 1,
            label: '約聘/派遣',
          },
          {
            value: 2,
            label: '實習',
          },
          {
            value: 3,
            label: '兼職',
          },
          {
            value: 4,
            label: '遠端工作',
          },
          {
            value: 5,
            label: '短期工讀',
          },
        ],
        salary: [
          {
            value: [160, null, 1],
            label: '時薪160UP',
          },
          {
            value: [30000, null, 2],
            label: '月薪3萬UP',
          },
          {
            value: [40000, null, 2],
            label: '月薪4萬UP',
          },
          {
            value: 'custom',
            label: '自訂',
          },
        ],
        seniority: [
          {
            value: [null, 1],
            label: '一年以下',
          },
          {
            value: [1, 3],
            label: '一到三年',
          },
          {
            value: [3, 5],
            label: '三到五年',
          },
          {
            value: [5, 10],
            label: '五到十年',
          },
          {
            value: [10, null],
            label: '十年以上',
          },
        ],
      },
      filterMenuSelect: {
        updateTime: null,
        type: null,
        salary: null,
        salaryType: null,
        seniority: null,
      },
      salaryInput: '',
      salarySelect: '2',
    };
  },
  methods: {
    clear() {
      this.filterMenuSelect = this.$options.data().filterMenuSelect;
      this.$emit('filterChange', {});
    },
    onChangeItem() {
      let { salary, salaryType } = this.filterMenuSelect;
      if (salary) {
        if (salary === 'custom') {
          salaryType = Number(this.salarySelect);
          salary = [Number(this.salaryInput), null];
        } else {
          salaryType = salary[2];
          salary = salary.slice(0, 2);
        }
      }

      let payload = {
        ...this.filterMenuSelect,
        salary,
        salaryType,
      };

      for (const [key, value] of Object.entries(payload)) {
        if (value === null || value === '') {
          delete payload[key];
        }
      }

      this.$emit('filterChange', cleanObj(payload));
    },
  },
};
